/* eslint:disable: no-any */
declare const require: any;
/* eslint:enable */

// Add polyfills
import 'core-js/features/promise';
import 'core-js/features/array';
import 'core-js/features/set';
import 'core-js/features/map';
import 'whatwg-fetch';
import 'element-remove';
import 'classlist-polyfill';
import 'string.prototype.startswith';
import 'string.prototype.repeat';
import 'intersection-observer';
import elementClosestPolyfill from 'element-closest';
elementClosestPolyfill(window);
import es5NativeShim from './custom-elements-es5-native-shim';
import './replaceWithPolyfill';
import './requestAnimationFramePolyfill';
import 'element-matches-polyfill';
import 'element-closest-polyfill';
import 'time-input-polyfill/auto';
import './objectEntriesPolyfill';
import './isIntegerPolyfill';
import 'url-search-params-polyfill';

/*
 * Then load a shim for es5 transpilers (typescript or babel)
 * https://github.com/webcomponents/webcomponentsjs#custom-elements-es5-adapterjs
 */
require('@webcomponents/webcomponentsjs/custom-elements-es5-adapter.js');

// Add webcomponents polyfill
require('@webcomponents/webcomponents-platform/webcomponents-platform');
require('@webcomponents/custom-elements/custom-elements.min');

// Check if the browser natively supports webcomponents (and es6)
const hasNativeCustomElements = Boolean(window.customElements);

/*
 * Then load a shim for es5 transpilers (typescript or babel)
 * https://github.com/webcomponents/webcomponentsjs#custom-elements-es5-adapterjs
 */
if (hasNativeCustomElements) {
    // Removed: import(/* webpackMode: "eager" */'@webcomponents/webcomponentsjs/custom-elements-es5-adapter');
    // The above code seem to cause problems.
    es5NativeShim();
}
