function ReplaceWithPolyfill() {
    'use-strict';

    const parent = this.parentNode;
    let i = arguments.length;
    let currentNode;

    if (!parent) {
        return;
    }

    if (!i) {
        parent.removeChild(this);
    }

    // The i-- decrements i and returns the value of i before the decrement
    while (i--) {
        // eslint-disable-next-line prefer-rest-params
        currentNode = arguments[i];
        if (typeof currentNode !== 'object') {
            currentNode = this.ownerDocument.createTextNode(currentNode);
        } else if (currentNode.parentNode) {
            currentNode.parentNode.removeChild(currentNode);
        }

        // The value of "i" below is after the decrement
        if (!i) {
            // If currentNode is the first argument (currentNode === arguments[0])
            parent.replaceChild(currentNode, this);
        } else {
            // If currentNode isn't the first
            parent.insertBefore(currentNode, this.previousSibling);
        }
    }
}
if (!Element.prototype.replaceWith) {
    Element.prototype.replaceWith = ReplaceWithPolyfill;
}

if (!CharacterData.prototype.replaceWith) {
    CharacterData.prototype.replaceWith = ReplaceWithPolyfill;
}

if (!DocumentType.prototype.replaceWith) {
    DocumentType.prototype.replaceWith = ReplaceWithPolyfill;
}
